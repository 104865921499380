import type {
  UseHomepageViewHook,
  UseHomepageViewProps,
} from '@views/HomePageView/HomePageView.types';
import { useEffect, useState } from 'react';
import {
  clearQueryParam,
  getQueryParams,
  queryParamsEventEmitter,
  setQueryParams,
} from 'react-use-query-param-string';

export const usePagination = ({
  posts,
}: UseHomepageViewProps): UseHomepageViewHook => {
  const POSTS_PER_PAGE = 10;
  const SCROLL_Y_POSITION = 100;
  const totalPages = Math.ceil(posts?.length / POSTS_PER_PAGE) || 1;
  const initialPosts = posts?.slice(0, POSTS_PER_PAGE);

  const [initialPageLoad, setInitialPageLoad] = useState<boolean>(true);
  const [currentPosts, setCurrentPosts] = useState(initialPosts);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [currentUrlParam, setCurrentUrlParam] = useState<string>('');

  const params = getQueryParams();

  const changePage = (page: number): void => {
    setCurrentPage(page);
  };

  useEffect(() => {
    if (initialPageLoad) {
      setInitialPageLoad(false);
    } else {
      window.scrollTo(0, SCROLL_Y_POSITION);
    }
    if (currentPage === 0) {
      setCurrentPosts(posts.slice(0, POSTS_PER_PAGE));
      clearQueryParam('strona');
      return;
    }

    setCurrentPosts(() => {
      if (currentPage === 0) {
        clearQueryParam('strona');
        return posts.slice(0, POSTS_PER_PAGE);
      }

      setQueryParams({ ...params, strona: String(currentPage + 1) });
      return posts.slice(
        currentPage * POSTS_PER_PAGE,
        currentPage * POSTS_PER_PAGE + POSTS_PER_PAGE,
      );
    });
  }, [currentPage, posts]);

  useEffect(() => {
    const pageNumberFromQuery = Number(params.strona);

    setCurrentPage(() => {
      if (!pageNumberFromQuery) return 0;
      if (pageNumberFromQuery + 1 > totalPages) return totalPages - 1;
      return pageNumberFromQuery - 1;
    });
  }, []);

  useEffect(() => {
    if (!window.location.origin) return;

    const updateCurrentParam = () => {
      const params = getQueryParams();
      const currentUrl = window?.location.origin;

      if (!params['strona']) {
        setCurrentUrlParam(currentUrl);
      } else {
        setCurrentUrlParam(`${currentUrl}/?strona=${params['strona']}`);
      }
    };

    queryParamsEventEmitter.addListener('update', updateCurrentParam);

    return () => {
      queryParamsEventEmitter.removeListener('update', updateCurrentParam);
    };
  }, []);

  return {
    currentPage,
    changePage,
    totalPages,
    currentParamUrl: currentUrlParam,
    currentPosts,
  };
};
