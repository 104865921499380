import Image from '@atoms/Image';
import Tag from '@atoms/Tag';
import Link from 'next/link';
import type { ReactElement } from 'react';

import {
  Excerpt,
  ImageWrapper,
  ListingCardWrapper,
  PostedAt,
  TagsWrapper,
  TextWrapper,
} from './ListingCard.styled';
import type { ListingCardProps } from './ListingCard.types';

export const ListingCard = ({
  image,
  tags,
  publishedAt,
  title,
  excerpt,
  link,
  priority = false,
}: ListingCardProps): ReactElement => (
  <Link href={link}>
    <ListingCardWrapper>
      <ImageWrapper>
        <Image
          alt={title}
          src={image.src}
          width={250}
          height={250}
          quality={50}
          sizes="(max-width: 991px) 150px, 250px"
          priority={priority}
          loading={priority ? 'eager' : 'lazy'}
        />
      </ImageWrapper>
      <TextWrapper>
        {tags?.length > 0 && (
          <TagsWrapper>
            {tags.map(tag => (
              <Tag key={tag.title} title={tag.title} />
            ))}
          </TagsWrapper>
        )}
        <PostedAt>{publishedAt}</PostedAt>
        <h2>{title}</h2>
        <Excerpt>{excerpt}</Excerpt>
      </TextWrapper>
    </ListingCardWrapper>
  </Link>
);
