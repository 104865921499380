import { LiveQueryProvider } from '@sanity/preview-kit';
import type { ReactElement, ReactNode } from 'react';
import { useMemo } from 'react';

import { getClient } from '../../lib/getClient';

interface PreviewProviderProps {
  children: ReactNode;
  previewToken: string;
}

export const PreviewProvider = ({
  children,
  previewToken,
}: PreviewProviderProps): ReactElement => {
  const client = useMemo(() => getClient(previewToken), [previewToken]);

  return <LiveQueryProvider client={client}>{children}</LiveQueryProvider>;
};
