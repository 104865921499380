import dynamic from 'next/dynamic';

const AboutMeCard = dynamic(() => import('@molecules/AboutMeCard'));
const InstagramPreview = dynamic(() => import('@organisms/InstagramPreview'));

import ColumnLayout from '@molecules/ColumnLayout';
import ListingCard from '@organisms/ListingCard';
import Head from 'next/head';
import type { ReactElement } from 'react';

import { usePagination } from '@/hooks/pagination.hook';
import { MobileCardsWrapper } from '@/styles/GlobalStyle';

import { CardWrapper } from './HomePageView.styled';
import type { HomePageViewProps } from './HomePageView.types';

export const HomePageView = ({
  columnLayout,
  posts,
}: HomePageViewProps): ReactElement => {
  const { currentParamUrl } = usePagination({
    posts,
  });

  return (
    <>
      <Head>
        <link rel="canonical" key="canonical" href={currentParamUrl} />
      </Head>
      <ColumnLayout {...columnLayout} hideBreadcrumbs>
        {posts.map((post, idx) => (
          <CardWrapper key={post.title}>
            <ListingCard priority={idx <= 4} {...post} />
          </CardWrapper>
        ))}
        {/*<PaginationWrapper>*/}
        {/*  <Pagination*/}
        {/*    currentPage={currentPage}*/}
        {/*    totalPages={totalPages}*/}
        {/*    onPageChange={({ selected }) => changePage(selected)}*/}
        {/*  />*/}
        {/*</PaginationWrapper>*/}
        <MobileCardsWrapper>
          <AboutMeCard />
          <InstagramPreview {...columnLayout.instagramFeed} />
        </MobileCardsWrapper>
      </ColumnLayout>
    </>
  );
};
