import styled from 'styled-components';

import { cardBackround } from '@/styles/shared';

export const ListingCardWrapper = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-auto-rows: 1fr;
  align-items: center;
  max-width: 755px;
  overflow: auto;
  ${cardBackround};
  min-height: 160px;

  @media ${({ theme }) => theme.media.tablet} {
    grid-template-columns: 1fr 2fr;
    width: 100%;
    min-height: 250px;

    &:hover {
      img {
        transform: scale(1.1);
        transition: transform 0.3s ease;
      }
    }
  }

  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 6px 0 0 6px;
    transition: transform 0.3s ease;

    @media ${({ theme }) => theme.media.tablet} {
      height: 100%;
    }
  }
`;

export const TextWrapper = styled.div`
  padding: 10px;
  display: grid;
  grid-template-areas:
    'tag'
    'postedAt'
    'title'
    'excerpt';

  @media ${({ theme }) => theme.media.tablet} {
    padding: 25px;

    grid-template-areas:
      'tag'
      'title'
      'postedAt'
      'excerpt';
  }

  h2 {
    grid-area: title;
    color: ${({ theme }) => theme.palette.dark};
    font-size: 18px;
    line-height: 22px;

    @media ${({ theme }) => theme.media.tablet} {
      margin: 10px 0 5px 0;
      font-size: 28px;
      line-height: 30px;
    }
  }

  div:first-of-type {
    grid-area: tag;
  }
`;

export const TagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;

  & div:not(:first-of-type) {
    background: white;
    border: 1px solid ${({ theme }) => theme.palette.primary};
    color: ${({ theme }) => theme.palette.dark};
    display: none;

    @media ${({ theme }) => theme.media.landscapeMobile} {
      display: block;
    }
  }
`;

export const Excerpt = styled.p`
  display: none;
  grid-area: excerpt;
  color: ${({ theme }) => theme.palette.dark};
  margin: 15px 0 5px 0;
  height: 80px;
  overflow: hidden;

  @media ${({ theme }) => theme.media.tablet} {
    display: block;
  }
`;

export const PostedAt = styled.p`
  grid-area: postedAt;
  color: ${({ theme }) => theme.palette.gray};

  @media ${({ theme }) => theme.media.tablet} {
    margin: 0;
  }
`;

export const ImageWrapper = styled.div`
  overflow: hidden;
  height: 100%;
`;
