import SeoHead from '@atoms/SeoHead';
import Layout from '@molecules/Layout';
import type { LayoutProps } from '@molecules/Layout/Layout.types';
import PreviewProvider from '@sanity/components/PreviewProvider';
import { getClient } from '@sanity/lib/getClient';
import HomePageView from '@views/HomePageView';
import type { HomePageViewProps } from '@views/HomePageView/HomePageView.types';
import type { GetStaticProps } from 'next';
import type { ReactElement } from 'react';

interface HomePageProps extends HomePageViewProps {
  layoutData: LayoutProps;
  preview: boolean;
  previewToken?: string;
}

const Home = ({
  posts,
  layoutData,
  columnLayout,
  preview = false,
  previewToken,
}: HomePageProps): ReactElement => {
  if (preview && previewToken) {
    return (
      <PreviewProvider previewToken={previewToken}>
        <SeoHead
          metaDescription="Blog o sposobach na tanie latanie, wartych uwagi miejscach, dobrym jedzeniu, sztuce i sposobach na motywację z rana! Wszystko w jednym miejscu!"
          title="Fox Paradox | Podróże. Sztuka. Motywacja. Blog osobisty"
        />
        <Layout {...layoutData}>
          <HomePageView posts={posts} columnLayout={columnLayout} />
        </Layout>
      </PreviewProvider>
    );
  }

  return (
    <>
      <SeoHead
        metaDescription="Blog o sposobach na tanie latanie, wartych uwagi miejscach, dobrym jedzeniu, sztuce i sposobach na motywację z rana! Wszystko w jednym miejscu!"
        title="Fox Paradox | Podróże. Sztuka. Motywacja. Blog osobisty"
      />
      <Layout {...layoutData}>
        <HomePageView posts={posts} columnLayout={columnLayout} />
      </Layout>
    </>
  );
};

export const getStaticProps: GetStaticProps = async ({ preview = false }) => {
  const previewToken = preview ? process.env.SANITY_READ_TOKEN : ``;
  if (preview && !previewToken) {
    throw new Error(
      `Preview mode is active, but SANITY_READ_TOKEN is not set in environment variables`,
    );
  }
  const client = getClient(previewToken);

  const { getBasicData, getAllCategories, getAllPosts } = await import(
    '@lib/sanity'
  );

  const { mapArticleCard } = await import('@mappers/articleCard');
  const { mapColumnLayout } = await import('@mappers/columnLayout');
  const { mapLayout } = await import('@mappers/layout');

  const allPosts = await getAllPosts(client);
  const allTags = await getAllCategories(client);
  const basicData = await getBasicData(client);

  return {
    props: {
      preview,
      posts: allPosts.map(mapArticleCard),
      layoutData: mapLayout(allTags),
      columnLayout: mapColumnLayout(basicData),
    },
  };
};
export default Home;
